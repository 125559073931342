<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1><a href="https://github.com/settings/organizations" target="_blank"><img :src="require('@/assets/images/icons/github.svg')" alt="GitHub" /></a>GitHub Actions <span class="green">•</span></h1>
        <p>Создать и после bash команда d чтобы конфиг появился локально</p>
      </div>
      <a class="btn" target="_blank" href="https://server.yablonev.art/services/yml">Открыть сервер</a>
    </div>
    <form class="w" @submit.prevent="send">
      <div class="fields">
        <div class="form-control">
          <label>Тип проекта</label>
          <div class="options">
            <div class="option" :class="{not_selected: type !== 'frontend'}" @click="type = 'frontend'">Frontend</div>
            <div class="option" :class="{not_selected: type !== 'backend'}" @click="type = 'backend'">Backend</div>
          </div>
        </div>
        <div v-show="type === 'backend'" class="form-control">
          <label>Перезапуск pm2</label>
          <div class="options">
            <div class="option" :class="{not_selected: !notification}" @click="notification = true">Включить</div>
            <div class="option" :class="{not_selected: notification}" @click="notification = false">Выключить</div>
          </div>
        </div>
        <div class="form-control">
          <label>Название ветки</label>
          <input v-model="branch_name" />
        </div>
        <div class="form-control">
          <label>Ссылка на GitHub</label>
          <input v-model="github_link" />
        </div>
        <div class="form-control">
          <label>Node.js версия</label>
          <input v-model="node_version" />
        </div>
        <div class="form-control">
          <label>ssh_user</label>
          <input v-model="ssh_user" />
        </div>
        <div class="form-control">
          <label>Название переменной в GitHub Secrets</label>
          <input v-model="secrets_var" />
        </div>
        <div class="form-control">
          <label>ssh_host</label>
          <input v-model="ssh_host" />
        </div>
        <div class="form-control">
          <label>Домен куда деплоим</label>
          <input v-model="domain" />
        </div>
        <div class="form-control" v-show="type === 'backend' && notification">
          <label>Endpoint для старта pm2</label>
          <input v-model="pm2_start" />
        </div>
      </div>
      <div class="row">
        <div class="flex">
          <a class="btn" target="_blank" :href="`https://github.com/${github_link}/settings/secrets/actions`">GitHub Secrets</a>
          <div @click="copy(command)" class="btn ml-14">{{ command }}</div>
        </div>
        <button class="btn ml-14 green" type="submit">Получить конфиг</button>
      </div>
    </form>
    <div class="action_result" v-show="result">
      <pre v-highlightjs="result"><code class="yml"></code></pre>
    </div>
    <button v-show="result" class="btn mt-20" @click="result = ''">Сбросить</button>
  </section>
</template>

<script>
import { API } from '@/logic/helper'
import copy from 'copy-to-clipboard'

export default {
  name: 'Deployment',
  data() {
    return {
      github_link: 'YablonevArt/System',
      type: 'frontend',
      notification: false,
      branch_name: 'main',
      node_version: '16.x',
      secrets_var: 'KEY',
      ssh_user: 'yablonev',
      ssh_host: 'yablonev.art',
      domain: 'system.yablonev.art',
      pm2_start: 'https://system.lucky-dapps.com/backend', // https://server.yablonev.art/services/deployment/pm2/backend
      command: 'pbcopy < ~/.ssh/id_rsa',
      result: '',
      copy
    }
  },
  methods: {
    async send() {
      const params = {
        type: `${this.type}_light`,
        branch_name: this.branch_name,
        node_version: this.node_version,
        secrets_var: this.secrets_var,
        ssh_user: this.ssh_user,
        ssh_host: this.ssh_host,
        domain: this.domain,
        pm2_start: this.pm2_start,
      }
      const result = await API('services/github_actions', { params })
      this.result = result.data
      this.result = this.notification ? this.result : this.result.replace(`      - name: 'Call API'
        continue-on-error: true
        uses: indiesdev/curl@v1
        id: pm2
        with:
          url: https://system.lucky-dapps.com/backend
          method: 'GET'`, '')
      this.copy(this.result)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/yml'

h1
  display: flex

  img
    position: relative
    margin-right: 5px
    top: 1px
    width: 26px
    height: 26px
</style>